import { useEffect, useRef, useState } from 'react';
import api from '../../libs/api';
import { Price } from '../../interfaces/price.interface';
import { formatNumber } from '../../functions/formatNumber';
import { captureException } from '@sentry/react';
import LandingPagePriceImage from '../../features/LandingPagePriceImage';

export default function TrendingFoodItems() {
  const [prices, setPrices] = useState<Price[]>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function getPrices() {
      try {
        const apiResponse = await api.get('/price/random');
        if (apiResponse.status === 200) {
          setPrices(apiResponse.data as Price[])
        }
      } catch (error) {
        captureException(error);
      }
    }
    void getPrices();
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.width = `${window.innerWidth}px`;
    }
  }, []);

  const ProductCard = ({ commodityName, averagePrice, marketName, unit }: Price) => {
    return (
      <div className="flex flex-col max-w-xs rounded-lg overflow-hidden shadow-lg bg-white">
        <LandingPagePriceImage commodityName={commodityName} />
        <div className="px-4 py-4">
          <div className="text-xl font-semibold text-gray-800">
            {commodityName}
          </div>
          <div className="text-2xl font-bold text-gray-900 mt-2">
            N{formatNumber(averagePrice)}
          </div>
          <span className="inline-block bg-gray-200 text-gray-700 text-xs font-semibold rounded-md px-3 py-1">
              {unit.replace('-', ' ')}
            </span>
          <div className="mt-2 justify-end">
            <span className="inline-block bg-gray-200 text-gray-700 text-xs font-semibold rounded-md px-3 py-1">
              {marketName}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="pt-18">
        <p className="text-center text-slate-900 dark:text-white text-2xl font-bold font-['Work Sans'] leading-10">
          Check Current Food Price
        </p>
        <p className="text-center text-slate-600 dark:text-white font-medium font-['Work Sans'] leading-normal mt-3 mb-5">
          Find out which food items people are searching the most
        </p>
      </div>
      <div className="flex justify-center px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
          {prices && prices.map((price) => (
            <ProductCard key={price._id} {...price} />
          ))}
        </div>
      </div>
    </div>
  );
}
